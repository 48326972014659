import React, { useState } from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { LoginFormFields } from '../../types/forms';
import { FieldText } from '../../components/FieldText';
import { InjectedFormProps, reduxForm } from 'redux-form';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    form: {

    }
}));

const LoginForm = (props:InjectedFormProps) => {
    const classes = useStyles();
    return (
        <form className={classes.form} noValidate={true} autoComplete="off" onSubmit={props.handleSubmit}>
            <Container className={classes.container} maxWidth="xs">
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                                <FieldText
                                    fullWidth={true}
                                    label="Uživatelské jméno"
                                    name="username"
                                    size="small"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <FieldText
                                    fullWidth={true}
                                    label="Heslo"
                                    name="password"
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button color="primary" fullWidth={true} type="submit" variant="contained">
                            Log in
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    );
};

export default reduxForm({
    form: 'LoginForm' // a unique identifier for this form
})(LoginForm);

