import { action } from 'typesafe-actions';
import { AppActionTypes, AppAuthenticateAction, AppConfig, AppReadyAction, AppStoreAuthenticateAction, LoadConfigAction, StoreConfigAction } from '../../types';

export const appStoreConfigAction = (payload: AppConfig): StoreConfigAction =>
    action(AppActionTypes.STORE_CONFIG, payload);

export const appLoadConfigAction = (): LoadConfigAction =>
    action(AppActionTypes.LOAD_CONFIG);

export const appReadyAction = (): AppReadyAction =>
    action(AppActionTypes.APP_READY);

export const appAuthenticateAction = (): AppAuthenticateAction =>
    action(AppActionTypes.APP_AUTHENTICATE);

export const appStoreAuthenticateAction = (): AppStoreAuthenticateAction =>
    action(AppActionTypes.STORE_AUTHENTICATE);
