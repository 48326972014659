import { Photo, PhotoConfig, ReduxState } from '../../types';

export const selectHasPhotos = (state: ReduxState): boolean => {
    if (state.photo.photos) {
        return Object.keys(state.photo.photos).length > 0;
    } return false;
};

export const selectTakenPhotos = (state: ReduxState): Photo[] => {
    const out:Photo[] = [];
    if (state.photo.photos) {
        const keys:string[] = Object.keys(state.photo.photos);
        keys.map(value => {
            if (state.photo.photos) {
                out.push(state.photo.photos[value]);
            }
        });
    }
    return out;
};

export const selectPhotoConfig = (state: ReduxState): PhotoConfig|undefined => {
    return state.photo.config;
};
