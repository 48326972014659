import React from 'react';
import { Button, LinearProgress, makeStyles, Modal, Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfo } from '../redux/selectors/infoSelectors';
import { InfoMessage } from '../types';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { deleteInfoAction } from '../redux/actions/infoActions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme:Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paperINFO: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #3f51b5',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column'
    },
    paperWARNING: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #edb17d',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column'
    },
    paperERROR: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #ff0000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column'
    }
}));

export const InfoModal = () => {
    const classes = useStyles();
    const history = useHistory();
    const infoMessage:InfoMessage|undefined = useSelector(selectInfo);
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(deleteInfoAction());
        if (infoMessage?.routeToGo) {
            history.push(infoMessage?.routeToGo);
        }
    };
    let paperClass = classes.paperINFO;

    if (infoMessage?.severity === 'ERROR') {
        paperClass = classes.paperERROR;
    } else if (infoMessage?.severity === 'WARNING') {
        paperClass = classes.paperWARNING;
    }

    return (
        <>
            {infoMessage &&
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                closeAfterTransition={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={true}>
                    <div className={paperClass}>
                        <h2 id="transition-modal-title">{infoMessage.title}</h2>
                        {infoMessage.type === 'progress' && <LinearProgress variant="determinate" value={infoMessage?.progress}/>}
                        {infoMessage.type === 'info' &&
                            <Button color="primary" onClick={handleCloseModal} variant="contained">
                                OK
                            </Button>
                        }
                    </div>
                </Fade>
            </Modal>
            }
        </>
    );
};
