export interface InfoMessage{
    progress?:number
    title:string
    type:'progress'|'info'
    routeToGo?:string
    severity?: 'ERROR'| 'WARNING'|'INFO'
}

export interface InfoState{
    message?:InfoMessage
}

export enum InfoActionTypes {
    ADD_INFO = 'info/ADD_INFO',
    DELETE_INFO = 'info/DELETE_INFO',
}

export interface AddInfoAction {
    type: InfoActionTypes.ADD_INFO
    payload: InfoMessage
}

export interface DeleteInfoAction {
    type: InfoActionTypes.DELETE_INFO
    payload?: any
}

export type InfoAction =
    AddInfoAction | DeleteInfoAction

