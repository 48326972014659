import { reducer as formReducer } from 'redux-form';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { appReducer } from './reducers/appReducer';
import { searchReducer } from './reducers/searchReducer';
import { photoReducer } from './reducers/photoReducer';
import { infoReducer } from './reducers/infoReducer';
import { ordersReducer } from './reducers/ordersReducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reducer = combineReducers<any>({
    form: formReducer,
    app: appReducer,
    search: searchReducer,
    photo: photoReducer,
    info: infoReducer,
    orders: ordersReducer
});

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    }
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let dispatch: any;

export default () => {
    const middleware = [];
    const sagaMiddleware = createSagaMiddleware();
    middleware.push(sagaMiddleware);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const store: any = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));
    store.sagaMiddleware = sagaMiddleware;
    dispatch = store.dispatch;
    return store;
};

export { dispatch };
