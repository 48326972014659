import React, { useCallback } from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CameraComponent } from '../components/CameraComponent';
import { storePhotoAction } from '../redux/actions/photoActions';
import { selectHasPhotos } from '../redux/selectors/photoSelectors';
import { useHistory } from 'react-router-dom';
import Routes from '../utils/Routes';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3)
    },
    input: {

    },
    viewPhotos: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    submitButton: {
        marginTop: theme.spacing(3)
    }
}));

export const AddPhotoPage = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const hasPhotos:boolean = useSelector(selectHasPhotos);

    const handleViewPhotos = () => {
        history.push(Routes.VIEW_TAKEN_PHOTOS);
    };

    const handleTakePhoto = useCallback((dataUri:string) => {
        dispatch(storePhotoAction({ id: (new Date()).getTime(), dataUri: dataUri }));
    }, []);

    return (
        <Grid container={true} className={classes.container} spacing={3} direction="column"
            justify="center"
            alignItems="center"
        >
            <CameraComponent handleTakePhoto={handleTakePhoto}/>
            <Grid item={true} xs={6}>
                {hasPhotos &&
                // @ts-ignore
                <Button className={classes.viewPhotos} color="primary" onClick={handleViewPhotos} fullWidth={true} variant="contained">
                    Prohlédnout fortografie
                </Button>
                }
            </Grid>
        </Grid>
    );
};
