import { RegistrEntity } from './registr';

export enum SearchActionTypes {
    SEARCH_BY_EVIDENCE_NUMBER = 'search/SEARCH_BY_EVIDENCE_NUMBER',
    STORE_SEARCH_RESULT = 'search/STORE_SEARCH_RESULT',
}
export interface SearchEvidenceNumberAction {
    type: SearchActionTypes.SEARCH_BY_EVIDENCE_NUMBER
}

export interface StoreSearchResultAction {
    type: SearchActionTypes.STORE_SEARCH_RESULT
    payload?:RegistrEntity[]
}

export type SearchAction =
    SearchEvidenceNumberAction | StoreSearchResultAction

