import React from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectPassParams } from '../redux/selectors/appSelectors';
import Routes from '../utils/Routes';
import { PhotoTypes } from '../types';
import { storePhotoTypeAction } from '../redux/actions/photoActions';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    }
}));

export const HomePage = () => {
    const classes = useStyles();
    const passed = useSelector(selectPassParams);
    const dispatch = useDispatch();
    const history = useHistory();

    const addPhotoToToOrder = () => {
        dispatch(storePhotoTypeAction(PhotoTypes.PHOTO_TYPE_ORDER));
        history.push(Routes.ORDER_ADD_PHOTO_PAGE);
    };

    return (
        <Container className={classes.container} maxWidth="xs">
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                    <Button color="primary" fullWidth={true} onClick={addPhotoToToOrder} variant="contained">
                         Přidat fortografii k zakázce {passed?.orderId}
                    </Button>
                </Grid>
            </Grid>

        </Container>
    );
};
