import React from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { FieldText, renderMediumTextField } from '../../components/FieldText';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { isRequired, isNumber } from '../../utils/validations';

const useStyles = makeStyles((theme) => ({
    form: {

    }
}));

const SearchEvidenceNumberForm = (props:InjectedFormProps) => {
    const classes = useStyles();
    return (
        <form className={classes.form} noValidate={true} autoComplete="off" onSubmit={props.handleSubmit}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12}>
                            <Field
                                name="search"
                                type="text"
                                component={renderMediumTextField}
                                label="Evidenční číslo"
                                validate={[isRequired, isNumber]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <Button color="primary" fullWidth={true} type="submit" variant="contained">
                            Hledat
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default reduxForm({
    form: 'SearchByEvidenceNumber'
})(SearchEvidenceNumberForm);

