import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import createSagaManager from './redux/configureSagas';

export const store = configureStore();
createSagaManager(store).start();

ReactDOM.render(
    <Provider store={store}>
        <div className="rs">
            <App />
        </div>
    </Provider>,
    document.getElementById('root')
);

