import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectTakenPhotos } from '../redux/selectors/photoSelectors';
import { useHistory } from 'react-router-dom';
import Routes from '../utils/Routes';
import { Photo, RegistrEntity } from '../types';
import { PhotoView } from '../components/PhotoView';
import { selectFirstRegistrSearchResult } from '../redux/selectors/searchSelectors';
import { uploadPhotosAction } from '../redux/actions/photoActions';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3)
    },
    title: {
        marginBottom: theme.spacing(3)
    },
    submitButton: {
        marginTop: theme.spacing(3)
    },
    takePhoto: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

export const ViewPhotosTakenPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const takenPhotos:Photo[] = useSelector(selectTakenPhotos);
    const firstResult :RegistrEntity | undefined = useSelector(selectFirstRegistrSearchResult);

    const handleTakePhotoClick = () => {
        dispatch(uploadPhotosAction());
    };

    return (
        <Grid container={true} className={classes.container} spacing={3} direction="column"
            justify="center"
            alignItems="center"
        >
            {firstResult &&
            <Grid direction="row" container={true} justify={'center'} spacing={3} className={classes.title}>
                <Grid item={true}><Typography variant="h5">{firstResult.itemname}</Typography></Grid>
                <Grid item={true}><Typography variant="h5">{firstResult.regnumber}</Typography></Grid>
            </Grid>
            }
            {takenPhotos.map((value, index) => {
                return <PhotoView key={`viewedPhoto${index}`} item={value}/>;
            })}

            <Button className={classes.submitButton} color={'primary'} onClick={handleTakePhotoClick} fullWidth={true} variant="contained">
                Uložit fotografie
            </Button>

            <Button className={classes.takePhoto} onClick={handleTakePhotoClick} fullWidth={true} variant="contained">
                Přidat fotku
            </Button>
        </Grid>
    );
};
