import React from 'react';
import { Field } from 'redux-form';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { TextField } from '@material-ui/core';

// @ts-ignore
export const renderMediumTextField = ({ input, meta: { touched, invalid, error }, ...custom }) => (
    <TextField
        fullWidth={true}
        size="medium"
        variant="outlined"
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
);
export const FieldText = (props:TextFieldProps) => {
    return (
        <Field
            name={props.name}
            component={renderMediumTextField}
            label={props.label}
            props={props}
        />);
};
