import React from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
// https://www.npmjs.com/package/react-html5-camera-photo

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LocalProps{
    handleTakePhoto: (value:string) => void
}

export const CameraComponent = React.memo((props:LocalProps) => {
    // @ts-ignore
    function handleTakePhoto(dataUri) {
        props.handleTakePhoto(dataUri);
        // Do stuff with the photo...
        // console.log('takePhoto', dataUri);
    }
    //
    // function handleTakePhotoAnimationDone(dataUri) {
    //     // Do stuff with the photo...
    //     console.log('takePhoto',dataUri));
    // }

    // @ts-ignore
    function handleCameraError(error) {
        console.log('handleCameraError', error);
    }
    //
    // function handleCameraStart(stream) {
    //     console.log('handleCameraStart',stream));
    // }
    //
    // function handleCameraStop() {
    //     console.log('handleCameraStop');
    // }

    return (
        <Camera
            onTakePhoto = {(dataUri) => { handleTakePhoto(dataUri); }}
            // onTakePhotoAnimationDone = {(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
            onCameraError = {(error) => { handleCameraError(error); }}
            idealFacingMode = {FACING_MODES.ENVIRONMENT}
            idealResolution = {{ width: 1280, height: 720 }}
            imageType = {IMAGE_TYPES.JPG}
            imageCompression = {0.97}
            isMaxResolution = {true}
            isImageMirror = {false}
            isSilentMode = {false}
            // isDisplayStartCameraError = {true}
            isFullscreen = {false}
            sizeFactor = {1}
            // onCameraStart = {(stream) => { handleCameraStart(stream); }}
            // onCameraStop = {() => { handleCameraStop(); }}

        />
    );
});
