import React, { useEffect } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { appLoadConfigAction } from './redux/actions/appActions';
import { selectAppAuthenticated, selectAppReady } from './redux/selectors/appSelectors';
import { BasicLayout } from './containers/App/BasicLayout';
import Routes from './utils/Routes';
import { LoginPage } from './pages/LoginPage';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { AuthLayout } from './containers/App/AuthLayout';
import { SearchByEvidenceNumberPage } from './pages/SearchByEvidenceNumberPage';
import { AddPhotoPage } from './pages/AddPhotoPage';
import { ViewPhotosTakenPage } from './pages/ViewPhotosTakenPage';
import { OrderAddPhotoPage } from './pages/OrderAddPhotoPage';

function App() {
    const dispatch = useDispatch();
    const appReady = useSelector(selectAppReady);
    const authenticated = useSelector(selectAppAuthenticated);

    useEffect(() => {
        dispatch(appLoadConfigAction());
    }, []);

    return (
        <>
            {appReady && authenticated && (
                <BrowserRouter>
                    <AuthLayout>
                        <Switch>
                            <Route exact={true} path={Routes.HOME} component={HomePage}/>
                            <Route exact={true} path={Routes.SEARCH_EVIDENCE_NUMBER} component={SearchByEvidenceNumberPage}/>
                            <Route exact={true} path={Routes.ADD_PHOTO} component={AddPhotoPage}/>
                            <Route exact={true} path={Routes.VIEW_TAKEN_PHOTOS} component={ViewPhotosTakenPage}/>
                            <Route exact={true} path={Routes.ORDER_ADD_PHOTO_PAGE} component={OrderAddPhotoPage}/>
                            <Redirect to={Routes.HOME}/>
                        </Switch>
                    </AuthLayout>
                </BrowserRouter>)}

            {appReady && !authenticated && (
                <BasicLayout>
                    <BrowserRouter>
                        <Switch>
                            <Route exact={true} path={Routes.LOGIN} component={LoginPage}/>
                            <Redirect to={Routes.LOGIN}/>
                        </Switch>
                    </BrowserRouter>
                </BasicLayout>)}
        </>
    );
}

export default App;
