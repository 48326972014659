import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import LoginForm from './forms/LoginForm';
import { useDispatch } from 'react-redux';
import { appAuthenticateAction } from '../redux/actions/appActions';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    }
}));

export const LoginPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // https://decembersoft.com/posts/changing-react-route-programmatically-with-redux-saga/
    const handleSubmit = () => {
        dispatch(appAuthenticateAction());
    };
    return (
        <Container className={classes.container} maxWidth="xs">
            <LoginForm onSubmit={handleSubmit}/>
        </Container>
    );
};
