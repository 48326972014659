import { PassParams, ReduxState } from '../../types';

export const selectAppReady = (state: ReduxState): boolean => {
    return state.app.ready;
};
export const selectAppAuthenticated = (state: ReduxState): boolean => {
    return state.app.authenticated;
};

export const selectPassParams = (state: ReduxState): PassParams|undefined => {
    return state.app.config?.passParams;
};
