import { all, fork, take, cancel } from 'redux-saga/effects';
import { Store } from 'redux';
import { appSagas } from './sagas/appSagas';
import { searchSagas } from './sagas/searchSagas';
import { photoSagas } from './sagas/photoSagas';
import { ordersSagas } from './sagas/ordersSagas';

export const CANCEL_ROOT_SAGA = 'CANCEL_ROOT_SAGA';

export function* rootSaga(): Generator {
    yield all([fork(appSagas)]);
    yield all([fork(searchSagas)]);
    yield all([fork(photoSagas)]);
    yield all([fork(ordersSagas)]);
}

export function* cancellableSaga(): Generator {
    // start the root saga
    const task = yield fork(rootSaga);

    // cancelling mechanism
    yield take(CANCEL_ROOT_SAGA);
    // @ts-ignore
    yield cancel(task);
}

export default function createSagaManager(store: Store) {
    return {
        cancel: () => store.dispatch({ type: CANCEL_ROOT_SAGA }),
        start: () => (store as any).sagaMiddleware.run(cancellableSaga)
    };
}
