import { OrdersEntity } from './orderApp';

export interface OrdersState{
    orderDetail:OrdersEntity|null
}

export enum OrdersActionTypes {
    GET_ORDER_DETAILS = 'orders/GET_ORDER_DETAILS',
    STORE_ORDER_DETAILS = 'orders/STORE_ORDER_DETAILS',
}
export interface GetOrderDetailAction {
    type: OrdersActionTypes.GET_ORDER_DETAILS
}
export interface StoreOrderDetailAction {
    type: OrdersActionTypes.STORE_ORDER_DETAILS
    payload: OrdersEntity
}

export type OrdersAction =
    GetOrderDetailAction | StoreOrderDetailAction

