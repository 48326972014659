import { call, put, select, takeEvery } from 'redux-saga/effects';
import { AppActionTypes, AppConfig, OResponse, PassParams } from '../../types';
import { appReadyAction, appStoreAuthenticateAction, appStoreConfigAction } from '../actions/appActions';
import api, { axiosInstance, processResponseStatus } from '../../api/api';
import { selectCredentials } from '../selectors/formSelectors';
import storage from '../../utils/localStorage';
import { LoginFormFields } from '../../types';
import { push } from 'react-router-redux';
import Routes from '../../utils/Routes';

export function* loadConfigSaga(): Generator {
    let passObject:PassParams = {};
    // @ts-ignore
    if (window.APP_PASS_PARAMS) {
        // @ts-ignore
        passObject = JSON.parse(atob(window.APP_PASS_PARAMS));
    }
    // @ts-ignore
    const config:AppConfig = { apiUrl: window.API_URL, appMode: window.APP_MODE, appName: window.APP_NAME, debugMode: window.APP_MODE === 'development', passParams: passObject };
    api.init(config);

    yield put(appStoreConfigAction(config));
    let response: OResponse = (yield call(axiosInstance.hello)) as OResponse;
    response = processResponseStatus(response);
    if (response.isHttpOk) {
        if (response.data && response.data.status === 'OK') {
            const token = storage.getItem('token');
            if (token) {
                const response: OResponse = (yield call(axiosInstance.jwtCheck, { token })) as OResponse;
                if (response.data && response.data.status === 'OK') {
                    api.init(config);
                    yield put(appStoreAuthenticateAction());
                    yield put(push(Routes.HOME));
                } else {
                    storage.clear();
                }
            }
            yield put(appReadyAction());
        }
    }
}

export function* authenticateSaga():Generator {
    const credentials: LoginFormFields = (yield select(selectCredentials)) as LoginFormFields;
    let response: OResponse = (yield call(axiosInstance.authenticate, credentials) ) as OResponse;
    response = processResponseStatus(response);
    if (response.isHttpOk) {
        // eslint-disable-next-line no-debugger
        debugger;
        storage.setItem('token', response.data.result);
        yield put(appStoreAuthenticateAction());
        yield put(push(Routes.HOME));
    }
}

export function* appSagas(): Generator {
    yield takeEvery(AppActionTypes.LOAD_CONFIG, loadConfigSaga);
    yield takeEvery(AppActionTypes.APP_AUTHENTICATE, authenticateSaga);
}
