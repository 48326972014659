import { LoginFormFields, OrderAddPhotoFields, PhotoEntity, SimpleSearchField } from '../types';
import { Photo } from '../types';
import { JwtCheckRequest } from './api';

export interface Endpoint {
    method: string
    url: string
    secured: boolean
}

export interface ApiEndpoints {
    [key: string]: Endpoint
}

export interface ApiInterface {
    hello: () => Promise<any>
    authenticate: (param:LoginFormFields) => Promise<any>
    searchByEvidenceNumber: (param:SimpleSearchField) => Promise<any>
    uploadPhotoOrderApp: (param:PhotoEntity) => Promise<any>
    uploadPhotoRegistr: (param:PhotoEntity) => Promise<any>
    jwtCheck: (param:JwtCheckRequest) => Promise<any>
    getOrderDetail: (param:OrderAddPhotoFields) => Promise<any>
}

export const apiEndpoints: ApiEndpoints = {
    hello: {
        method: 'GET',
        url: '/hello',
        secured: false
    },
    authenticate: {
        method: 'POST',
        url: '/authenticate',
        secured: false
    },
    searchByEvidenceNumber: {
        method: 'POST',
        url: '/searchByEvidenceNumber',
        secured: true
    },
    uploadPhotoOrderApp: {
        method: 'POST',
        url: '/uploadPhotoOrderApp',
        secured: true
    },
    uploadPhotoRegistr: {
        method: 'POST',
        url: '/uploadPhotoRegistr',
        secured: true
    },
    jwtCheck: {
        method: 'POST',
        url: '/jwtCheck',
        secured: false
    },
    getOrderDetail: {
        method: 'POST',
        url: '/getOrderDetail',
        secured: true
    }
};

