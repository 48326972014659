import { ReduxState, LoginFormFields, SimpleSearchField, OrderAddPhotoFields } from '../../types';

export const selectCredentials = (state: ReduxState): LoginFormFields => {
    return state.form.LoginForm.values;
};

export const selectSearchByEvidenceNumber = (state: ReduxState): SimpleSearchField => {
    return state.form.SearchByEvidenceNumber.values;
};

export const selectOrderAddPhotoFields = (state: ReduxState): OrderAddPhotoFields => {
    return state.form.OrderAddPhotoForm.values;
};

