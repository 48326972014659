import { ReduxState, RegistrEntity } from '../../types';

export const selectRegistrSearchResult = (state: ReduxState): RegistrEntity[] | undefined => {
    return state.search.registrResult;
};

export const selectFirstRegistrSearchResult = (state: ReduxState): RegistrEntity | undefined => {
    if (state.search.registrResult) {
        return state.search.registrResult[0];
    }
    return undefined;
};
