import React from 'react';

export const isRequired = (value:any) => (value || typeof value === 'number' ? undefined : 'Povinné pole');

const maxLength = (max:number) => (value:any) =>
    value && value.length > max ? `Musí být ${max} znaků nebo míň.` : undefined;

export const maxLength15 = maxLength(15);
export const minLength = (min:number) => (value:any) =>
    value && value.length < min ? `Musí být ${min} znaků nebo víc.` : undefined;
export const minLength2 = minLength(2);
export const isNumber = (value:any) =>
    value && isNaN(Number(value)) ? 'Musí být číslo' : undefined;
const minValue = (min:number) => (value:any) =>
    value && value < min ? `Musí být alespoň ${min}` : undefined;
export const minValue13 = minValue(13);
export const email = (value:any) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Neplatný email'
        : undefined;

export const alphaNumeric = (value:any) =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Pouze čísla nebo písmena'
        : undefined;
export const phoneNumber = (value:any) =>
    value && !/^(0|[1-9][0-9]{9})$/i.test(value)
        ? 'Invalid phone number, must be 10 digits'
        : undefined;

