import { call, put, select, takeEvery } from 'redux-saga/effects';
import { OResponse } from '../../types';
import { axiosInstance, processResponseStatus } from '../../api/api';
import { selectSearchByEvidenceNumber } from '../selectors/formSelectors';
import { SimpleSearchField } from '../../types';

import { SearchActionTypes } from '../../types/searchActions';
import { storeSearchResultAction } from '../actions/searchActions';

export function* getSearchByEvidenceNumber():Generator {
    const fields: SimpleSearchField = (yield select(selectSearchByEvidenceNumber)) as SimpleSearchField;
    let response: OResponse = (yield call(axiosInstance.searchByEvidenceNumber, fields)) as OResponse;
    response = processResponseStatus(response);
    if (response.isHttpOk) {
        yield put(storeSearchResultAction(response.data.result));
    }
}

export function* searchSagas(): Generator {
    yield takeEvery(SearchActionTypes.SEARCH_BY_EVIDENCE_NUMBER, getSearchByEvidenceNumber);
}
