import { call, put, select, takeEvery } from 'redux-saga/effects';
import { OrdersEntity, OResponse, Photo, PhotoActionTypes, PhotoConfig, PhotoConfigTypes, PhotoEntity, RegistrEntity } from '../../types';
import { axiosInstance, processResponseStatus } from '../../api/api';
import { selectPhotoConfig, selectTakenPhotos } from '../selectors/photoSelectors';
import { selectFirstRegistrSearchResult } from '../selectors/searchSelectors';
import { addInfoAction } from '../actions/infoActions';
import Routes from '../../utils/Routes';
import { selectOrderDetail } from '../selectors/ordersSelectors';
import { clearPhotosAction } from '../actions/photoActions';

export function* uploadPhotos():Generator {
    const photos: Photo[] = (yield select(selectTakenPhotos)) as Photo[];

    // @ts-ignore
    let allPhotoProps :PhotoEntity = {};

    const photoConfig: PhotoConfig = (yield select(selectPhotoConfig)) as PhotoConfig;
    if (photoConfig) {
        switch (photoConfig.type) {
        case PhotoConfigTypes.ORDER: {
            const ordersEntity: OrdersEntity = (yield select(selectOrderDetail)) as OrdersEntity;
            // @ts-ignore
            allPhotoProps = {
                description: '',
                orderId: ordersEntity.id,
                name: ''
            };
            break;
        }
        case PhotoConfigTypes.REGISTR: {
            const registerEntity: RegistrEntity = (yield select(selectFirstRegistrSearchResult)) as RegistrEntity;
            // @ts-ignore
            allPhotoProps = {
                description: '',
                productNumber: `${Number(registerEntity.regnumber)}`,
                name: registerEntity.itemname
            };
            break;
        }
        default: return;
        }
    }

    const step: number = 100 / photos.length;
    let i = 0;

    yield put(addInfoAction({
        title: 'Nahrávám fotografie',
        type: 'progress',
        progress: 0
    }));

    while (i < photos.length) {
        const photo:Photo = photos[i];
        const photoToSave:PhotoEntity = {
            dataUri: photo.dataUri,
            orderId: allPhotoProps.orderId,
            description: allPhotoProps.description,
            productNumber: allPhotoProps.productNumber,
            name: allPhotoProps.name
        };

        yield call(uploadPhoto, photoToSave);
        i++;
        yield put(addInfoAction({
            title: 'Nahrávám fotografie',
            type: 'progress',
            progress: i * step
        }));
    }
    yield put(clearPhotosAction());
    yield put(addInfoAction({
        title: 'Nahrávání dokončeno',
        type: 'info',
        routeToGo: Routes.HOME
    }));
}

export function* uploadPhoto(photo:PhotoEntity):Generator {
    const photoConfig: PhotoConfig = (yield select(selectPhotoConfig)) as PhotoConfig;
    let response: OResponse|undefined = undefined;
    if (photoConfig) {
        switch (photoConfig.type) {
        case PhotoConfigTypes.ORDER: {
            response = (yield call(axiosInstance.uploadPhotoOrderApp, photo)) as OResponse;
            response = processResponseStatus(response);
            // TODO process something went wrong
            break;
        }

        case PhotoConfigTypes.REGISTR: {
            response = (yield call(axiosInstance.uploadPhotoRegistr, photo)) as OResponse;
            response = processResponseStatus(response);
            // TODO process something went wrong
            break;
        }
        default: return;
        }
    }
}

export function* photoSagas(): Generator {
    yield takeEvery(PhotoActionTypes.UPLOAD_PHOTOS, uploadPhotos);
}
