import { call, put, select, takeEvery } from 'redux-saga/effects';
import { OrderAddPhotoFields, OrdersActionTypes, OResponse } from '../../types';
import { axiosInstance, processResponseStatus } from '../../api/api';
import { selectOrderAddPhotoFields } from '../selectors/formSelectors';
import { storeOrderDetailAction } from '../actions/ordersActions';
import { addInfoAction } from '../actions/infoActions';

export function* getOrderDetails():Generator {
    const fields: OrderAddPhotoFields = (yield select(selectOrderAddPhotoFields)) as OrderAddPhotoFields;
    let response: OResponse = (yield call(axiosInstance.getOrderDetail, fields)) as OResponse;
    response = processResponseStatus(response);
    if (response.isHttpOk) {
        if (response.data.result === null) {
            yield put(addInfoAction({
                title: 'Pod tímto číslem nebylo nic nalezeno.',
                type: 'info'
            }));
        }
        yield put(storeOrderDetailAction(response.data.result));
    }
}

export function* ordersSagas(): Generator {
    yield takeEvery(OrdersActionTypes.GET_ORDER_DETAILS, getOrderDetails);
}
