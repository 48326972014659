import { Reducer } from 'redux';
import { OrdersAction, OrdersActionTypes, OrdersState } from '../../types';

const initialState: OrdersState = {
    orderDetail: null
};

export const ordersReducer: Reducer<OrdersState> = (state = initialState, action: OrdersAction) => {
    switch (action.type) {
    case OrdersActionTypes.STORE_ORDER_DETAILS: {
        return { ...state, orderDetail: action.payload };
    }
    default:
        return state;
    }
};
