import React from 'react';
import { Grid, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { Photo } from '../types';
import DeleteIcon from '@material-ui/icons/Delete';
import { searchEvidenceNumberAction } from '../redux/actions/searchActions';
import { useDispatch } from 'react-redux';
import { deleteTakenPhotoAction } from '../redux/actions/photoActions';

interface LocalProps {
    item:Photo
}
const useStyles = makeStyles((theme:Theme) => ({
    root: {

    },
    image: {
        width: '100%'
    },
    deleteButton: {
        position: 'absolute',
        right: 14
    }
}));

export const PhotoView = (props:LocalProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleDeleteClick = (id:number) => {
        dispatch(deleteTakenPhotoAction(id));
    };

    return (
        <Paper className={classes.root} >
            <IconButton onClick={() => {handleDeleteClick(props.item.id);}} aria-label="delete" className={classes.deleteButton}>
                <DeleteIcon fontSize="large" />
            </IconButton>
            <img className={classes.image} srcSet={props.item.dataUri} />
        </Paper>
    );
};
