import { Reducer } from 'redux';
import { AppAction, AppActionTypes, AppState } from '../../types';

const initialState: AppState = {
    ready: false,
    authenticated: false
};

export const appReducer: Reducer<AppState> = (state = initialState, action: AppAction) => {
    switch (action.type) {
    case AppActionTypes.STORE_CONFIG: {
        return { ...state, config: action.payload, ready: true };
    }
    case AppActionTypes.STORE_AUTHENTICATE: {
        return { ...state, authenticated: true };
    }
    default:
        return state;
    }
};
