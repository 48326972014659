import { AnyAction } from 'redux';
import { Photo } from './appActions';

export enum PhotoActionTypes {
    CLEAR_PHOTOS = 'photo/CLEAR_PHOTOS',
    DELETE_TAKEN_PHOTO = 'photo/DELETE_TAKEN_PHOTO',
    START_PHOTO = 'photo/START_PHOTO',
    STORE_PHOTO = 'photo/STORE_PHOTO',
    STORE_PHOTO_TYPE = 'photo/STORE_PHOTO_TYPE',
    UPLOAD_PHOTOS = 'photo/UPLOAD_PHOTOS',
}

export enum PhotoTypes {
    PHOTO_TYPE_ORDER = 'PHOTO_TYPE_ORDER',
}

export enum PhotoConfigTypes {
    ORDER = 'ORDER',
    REGISTR = 'REGISTR',
}

export interface PhotoConfig {
    type: PhotoConfigTypes
    routeToGo?:string
}

export interface StorePhotoAction {
    type: PhotoActionTypes.STORE_PHOTO
    payload: Photo
}

export interface StorePhotoTypeAction {
    type: PhotoActionTypes.STORE_PHOTO_TYPE
    payload: PhotoTypes
}
export interface DeleteTakenPhotoAction {
    type: PhotoActionTypes.DELETE_TAKEN_PHOTO
    payload: number
}

export interface StartPhotoAction {
    type: PhotoActionTypes.START_PHOTO
    payload: PhotoConfig
}

export interface UploadPhotosAction {
    type: PhotoActionTypes.UPLOAD_PHOTOS
    payload?: number
}
export interface ClearPhotosAction {
    type: PhotoActionTypes.CLEAR_PHOTOS
    payload?: string
}

export type PhotoAction =
    DeleteTakenPhotoAction | ClearPhotosAction | StorePhotoAction | StartPhotoAction | AnyAction

