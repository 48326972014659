import { Reducer } from 'redux';
import { InfoAction, InfoActionTypes, InfoState } from '../../types';

const initialState: InfoState = {

};

export const infoReducer: Reducer<InfoState> = (state = initialState, action: InfoAction) => {
    switch (action.type) {
    case InfoActionTypes.ADD_INFO: {
        return { ...state, message: action.payload, ready: true };
    }
    case InfoActionTypes.DELETE_INFO: {
        return { ...state, message: undefined };
    }
    default:
        return state;
    }
};
