import { Reducer } from 'redux';
import { PhotoAction, PhotoActionTypes, PhotoMap, PhotoState, SearchState } from '../../types';

const initialState: PhotoState = {};

export const photoReducer: Reducer<PhotoState> = (state = initialState, action: PhotoAction) => {
    switch (action.type) {
    case PhotoActionTypes.START_PHOTO: {
        return { ...state, config: action.payload };
    }
    case PhotoActionTypes.CLEAR_PHOTOS: {
        return { ...state, photos: undefined };
    }
    case PhotoActionTypes.STORE_PHOTO: {
        const newPhotos: PhotoMap = { ...state.photos };
        newPhotos[action.payload.id] = action.payload;
        return { ...state, photos: newPhotos };
    }
    case PhotoActionTypes.DELETE_TAKEN_PHOTO: {
        const newPhotos: PhotoMap = { ...state.photos };
        delete newPhotos[action.payload];
        return { ...state, photos: newPhotos };
    }
    default:
        return state;
    }
};
