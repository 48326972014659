import { RegistrEntity } from './registr';
import { PhotoConfig } from './photoActions';

export interface PassParams {
    orderId?:string
    from?:string
}
export interface AppConfig {
    apiUrl:string
    appMode:string
    appName:string
    debugMode:boolean
    passParams:PassParams
}

export interface AppState{
    config?: AppConfig
    ready:boolean
    authenticated:boolean
}

export interface SearchState{
    evidenceNumber?: string
    registrResult?: RegistrEntity[]
}

export interface Photo {
    id: number
    dataUri:string
    regNumber?:number
}
export interface PhotoMap {
    [key: string]: Photo
}
export interface PhotoState{
    photos?: PhotoMap
    config?: PhotoConfig
}

export enum AppActionTypes {
    LOAD_CONFIG = 'app/LOAD_CONFIG',
    STORE_CONFIG = 'app/STORE_CONFIG',
    APP_READY = 'app/APP_READY',
    APP_AUTHENTICATE = 'app/APP_AUTHENTICATE',
    STORE_AUTHENTICATE = 'app/APP_STORE_AUTHENTICATE',
}

export interface AppReadyAction {
    type: AppActionTypes.APP_READY
}
export interface LoadConfigAction {
    type: AppActionTypes.LOAD_CONFIG
}
export interface AppAuthenticateAction {
    type: AppActionTypes.APP_AUTHENTICATE
}
export interface AppStoreAuthenticateAction {
    type: AppActionTypes.STORE_AUTHENTICATE
}
export interface StoreConfigAction {
    type: AppActionTypes.STORE_CONFIG
    payload: AppConfig
}

export type AppAction =
    LoadConfigAction | StoreConfigAction | AppStoreAuthenticateAction

