import { action } from 'typesafe-actions';
import { ClearPhotosAction, DeleteTakenPhotoAction, Photo, PhotoActionTypes, PhotoConfig, PhotoTypes, StartPhotoAction, StorePhotoAction, StorePhotoTypeAction, UploadPhotosAction } from '../../types';

export const storePhotoAction = (payload:Photo): StorePhotoAction =>
    action(PhotoActionTypes.STORE_PHOTO, payload);

export const storePhotoTypeAction = (payload:PhotoTypes): StorePhotoTypeAction =>
    action(PhotoActionTypes.STORE_PHOTO_TYPE, payload);

export const deleteTakenPhotoAction = (payload:number): DeleteTakenPhotoAction =>
    action(PhotoActionTypes.DELETE_TAKEN_PHOTO, payload);

export const clearPhotosAction = (): ClearPhotosAction =>
    action(PhotoActionTypes.CLEAR_PHOTOS);

export const uploadPhotosAction = (): UploadPhotosAction =>
    action(PhotoActionTypes.UPLOAD_PHOTOS);

export const startPhotoAction = (payload:PhotoConfig): StartPhotoAction =>
    action(PhotoActionTypes.START_PHOTO, payload);
