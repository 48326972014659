import React from 'react';
import { Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';

import OrderAddPhotoForm from './forms/OrderAddPhotoForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectPassParams } from '../redux/selectors/appSelectors';
import { getOrderDetailAction } from '../redux/actions/ordersActions';
import { selectOrderDetail } from '../redux/selectors/ordersSelectors';
import { OrdersEntity, PhotoConfigTypes } from '../types';
import { startPhotoAction } from '../redux/actions/photoActions';
import Routes from '../utils/Routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    }
}));

export const OrderAddPhotoPage = () => {
    const classes = useStyles();
    const passed = useSelector(selectPassParams);
    const orderDetail: OrdersEntity|null = useSelector(selectOrderDetail);
    const dispatch = useDispatch();
    const history = useHistory();
    const loadOrderDetail = () => {
        dispatch(getOrderDetailAction());
    };

    const addPhotoToOrder = () => {
        dispatch(startPhotoAction({ type: PhotoConfigTypes.ORDER, routeToGo: Routes.HOME }));
        history.push(Routes.ADD_PHOTO);
    };
    return (
        <Container className={classes.container} maxWidth="xs">
            <OrderAddPhotoForm initialValues={{ orderId: passed?.orderId }}/>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                    <Button color="primary" disabled={orderDetail === null} fullWidth={true} onClick={addPhotoToOrder} variant="contained">
                        Přidat fotografii
                    </Button>
                </Grid>
                <Grid item={true} xs={12}>
                    <Button fullWidth={true} onClick={loadOrderDetail} variant="contained">Načíst detail</Button>
                </Grid>
            </Grid>
            {orderDetail &&
                (<Grid container={true} spacing={2}>
                    <Grid item={true} xs={6}>
                        <Typography variant="body1" gutterBottom={true} >Poznámka</Typography>
                    </Grid>
                    <Grid item={true} xs={6} >
                        <Typography variant="body1" gutterBottom={true} >{orderDetail.note}</Typography>
                    </Grid>

                    <Grid item={true} xs={6}>
                        <Typography variant="body1" gutterBottom={true} >Evidenční číslo</Typography>
                    </Grid>
                    <Grid item={true} xs={6} >
                        <Typography variant="body1" gutterBottom={true} >{orderDetail.evidNo}</Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Typography variant="body1" gutterBottom={true} >Zde může být cokoliv, je načtená celá zakázka</Typography>
                    </Grid>
                </Grid>)
            }
        </Container>
    );
};
