import { Reducer } from 'redux';
import { SearchState } from '../../types';
import { SearchAction, SearchActionTypes } from '../../types/searchActions';

const initialState: SearchState = {};

export const searchReducer: Reducer<SearchState> = (state = initialState, action: SearchAction) => {
    switch (action.type) {
    case SearchActionTypes.STORE_SEARCH_RESULT: {
        return { ...state, registrResult: action.payload };
    }
    default:
        return state;
    }
};
