import React, { useState } from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { FieldText } from '../../components/FieldText';
import { InjectedFormProps, reduxForm } from 'redux-form';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    form: {

    }
}));

const OrderAddPhotoForm = (props:InjectedFormProps) => {
    const classes = useStyles();
    return (
        <form className={classes.form} noValidate={true} autoComplete="off" onSubmit={props.handleSubmit}>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                    <FieldText
                        fullWidth={true}
                        label="Číslo zakázky"
                        name="orderId"
                        size="small"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default reduxForm({
    form: 'OrderAddPhotoForm' // a unique identifier for this form
})(OrderAddPhotoForm);

