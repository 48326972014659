import React from 'react';
import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { RegistrEntity } from '../types';

interface LocalProps {
    item:RegistrEntity
}
const useStyles = makeStyles((theme:Theme) => ({
    root: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}));

export const RegistrEntityView = (props:LocalProps) => {
    const classes = useStyles();
    return (
        <Paper className={classes.root} >
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                    <Typography variant="body1" >Název pomůcky</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="body2" >{props.item.itemname}</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="body1" >Jméno</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="body2" >{props.item.name}</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="body1" >Datum doručení / vrácení</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="body2" >{props.item.deliverydate} / {props.item.returndate}</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="body1" >Konec životnosti</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography variant="body2" >{props.item.endlifedate}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
