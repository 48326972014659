import { AppState, PhotoState, SearchState } from './appActions';
import { InfoState } from './infoActions';
import { OrdersState } from './ordersActions';

export * from './appActions';
export * from './ordersActions';
export * from './registr';
export * from './forms';
export * from './photoActions';
export * from './infoActions';
export * from './ordersActions';
export * from './orderApp';

export interface ReduxState {
    app: AppState
    form: any
    search: SearchState
    photo:PhotoState
    info: InfoState
    orders: OrdersState
}

export interface ResponseData {
    status: 'ERROR'|'WARNING'|'OK'
    result: any
}

export interface OResponse extends Response {
    data: ResponseData
    isHttpOk: boolean
}

