import React, { ReactElement } from 'react';
import { Container } from '@material-ui/core';
import { InfoModal } from '../../components/InfoModal';

interface LocalProps {
    children: ReactElement
}
export const BasicLayout = (props:LocalProps) => {
    return (
        <Container maxWidth="sm">
            {props.children}
            <InfoModal/>
        </Container>
    );
};
