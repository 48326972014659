import React from 'react';
import { Button, Container, makeStyles } from '@material-ui/core';
import SearchEvidenceNumberForm from './forms/SearchEvidenceNumberForm';
import { useDispatch, useSelector } from 'react-redux';
import { searchEvidenceNumberAction } from '../redux/actions/searchActions';
import { RegistrEntity } from '../types';
import { selectRegistrSearchResult } from '../redux/selectors/searchSelectors';
import { RegistrEntityView } from '../components/RegistrEntityView';
import { useHistory } from 'react-router-dom';
import Routes from '../utils/Routes';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3)
    },
    input: {

    },
    takePhoto: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    submitButton: {
        marginTop: theme.spacing(3)
    }
}));

export const SearchByEvidenceNumberPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const items:RegistrEntity[] | undefined = useSelector(selectRegistrSearchResult);
    const handleSubmit = () => {
        dispatch(searchEvidenceNumberAction());
    };

    const handleTakePhotoClick = () => {
        history.push(Routes.ADD_PHOTO);
    };

    return (
        <Container className={classes.container} maxWidth="xs" >
            <SearchEvidenceNumberForm onSubmit={handleSubmit} initialValues={{ search: '010340002942' }}/>
            {items &&
                <Button className={classes.takePhoto} onClick={handleTakePhotoClick} fullWidth={true} variant="contained">
                    Přidat fotku
                </Button>
            }
            {items && items.map((value, index) => {
                return <RegistrEntityView key={`RegistrEntityView${index}`} item={value}/>;
            })}
        </Container>
    );
};
